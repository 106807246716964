import React, { useState, useEffect } from 'react';
import PostList from '../../components/wordpress/post-list';
import InfiniteScroll from '../../components/wordpress/infinite-scroll';
import { fetchPosts, fetchCategories } from '../../lib/utils';
import SeoMetadata from '../../components/shared/seo-metadata';
import { BlogTitle, BlogWrapper, SectionTitle, Loading } from '../../components/wordpress/styles';
import BlogLayout from '../../components/wordpress/blog-layout';
import { CategoriesList, MostViewedPosts } from '../../components/wordpress';

export async function getStaticProps() {
  const [initialPosts, categories, mostViewedPosts] = await Promise.all([
    fetchPosts(1),
    fetchCategories(),
    fetchPosts(1, 1422, 3),
  ]);

  const pageContext = {
    title: 'Blog',
  };

  return {
    props: {
      pageContext,
      initialPosts: initialPosts || [],
      categories: categories || [],
      mostViewedPosts: mostViewedPosts || [],
    },
    revalidate: 86400,
  };
}

const Blog = ({ initialPosts, sharedData, ...pageProps }) => {
  const { headerFooterData, globalMicrodata } = sharedData;

  const [posts, setPosts] = useState(initialPosts || []);
  const [page, setPage] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const { categories, mostViewedPosts } = pageProps
  const [hasMore, setHasMore] = useState(true);

  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    if (page === 1) return;

    const fetchWithDelay = async () => {
      setIsLoading(true);
      try {
        const newPosts = await fetchPosts(page, null, 3);
        if (newPosts.length === 0) {
          setHasMore(false);
        } else {
          setPosts(prevPosts => [...prevPosts, ...newPosts]);
        }
      } catch (error) {
        setHasMore(false);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    fetchWithDelay();
  }, [page]);

  return (
    <div>
      <SeoMetadata
        title="Qida - Calidad de vida"
        description="Calidad de vida"
        absoluteUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/blog`}
        shareImage={null}
        keywords={null}
        noIndex={false}
      />
      <BlogLayout pageProps={{ ...headerFooterData, globalMicrodata }}>
        <BlogWrapper>
          <BlogTitle>El blog del cuidado a domicilio experto y humano</BlogTitle>

          {categories.length > 0 && <CategoriesList categories={categories} />}
          <MostViewedPosts preFetchedPosts={mostViewedPosts} />
          {posts &&
            <div>
              <SectionTitle>
                <h2>Todos los artículos</h2>
                <hr />
              </SectionTitle>
              <PostList posts={posts} />
            </div>
          }
          {isLoading && <Loading>Cargando...</Loading>}
          {hasMore && <InfiniteScroll loadMore={loadMore} isLoading={isLoading} hasMore={hasMore} />}
        </BlogWrapper>
      </BlogLayout>
    </div>
  );
};
export default Blog;
