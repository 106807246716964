import React, { useEffect, useRef } from 'react';

const InfiniteScroll = ({ loadMore, isLoading, hasMore }) => {
  const loader = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading && hasMore) {
        loadMore();
      }
    }, option);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [loadMore, isLoading, hasMore]);

  return <div ref={loader} />;
};

export default InfiniteScroll;